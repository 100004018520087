.cart .upsell-background::before {
  content: '';
  background-image: url('../../assets/imgs/check.svg');
  /* Path to your image */
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  /* right: 0px;
  top: 0px; */
  z-index: 1;
  width: 35px;
  /* Adjust image width */
  height: 235px;
}

.max-limit {
  color: #052c427f;
  font-family: 'Librefranklin-Regular';
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.15px;
  font-size: 14px !important;
}

.upsells-details {
  display: flex;
  /* align-items: center; */
  position: absolute;
  justify-content: center;
  /* top: 4px; */
  width: 100px;
  height: 22px;
}

.upsells-details .quantity {
  border-radius: 5px !important;
  width: 80% !important;

}

.upsells-details .quantity .input-quantity {
  border: none !important;
  color: #000;
}

.complete-your-order {
  padding: 0px !important;
  background: 'EEEDE9';
}

/* .complete-your-order .makeStyles-cartRoot-85 {
  padding: 0px !important;
  margin: 0px !important;
} */

.scrollBar::-webkit-scrollbar {
  display: none;
}

.my-bag-header .css-1octwah-MuiButtonBase-root-MuiButton-root {
  padding: 0px !important;
}

/* @media screen and (max-width: 600px) {

 
  .spacing-quantity-btn {
    margin-left: 5px !important;
  }
} */
.sr-only {
  position: absolute;
  left: -1000px;
  top: auto;
  overflow: hidden;
  width: 1px;
  height: 1px;

}