#onetrust-banner-sdk {
    display: none;
}

/* .dailog-parent-container .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px !important;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100% !important;
} */
.css-m9glnp-MuiPaper-root-MuiDialog-paper {
    border-radius: 16px !important;
    /* overflow-y: hidden; */
}

.dailog-parent-container .Paper-root {
    border-radius: 15px !important;
}

#google-map-location-change {
    top: 0 !important;
}

.sign-up-section .MuiGrid-grid-xs-12 {
    padding-top: 24px;
}

.sign-up-section .label {
    display: block;
    width: 100%;
}

.sign-up-section h1 {
    margin: 5px 0px 10px 0px;
    font-size: 45px;
}

.sign-up-section .react-date-inputs input {
    width: 10%;
    background: white;
    border: none;
    /* height: auto; */
    -webkit-appearance: none;
    background-clip: padding-box;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(161, 161, 161, 0.30) !important;
    -moz-box-shadow: 0px 0px 13px 0px rgba(161, 161, 161, 0.30) !important;
    box-shadow: 0px 0px 13px 0px rgba(161, 161, 161, 0.30) !important;
    padding: 16px 14px;
    margin: 5px 12px 0px 0px;
    outline: none;
    caret-color: black !important;
    border-radius: 16px;
    text-align: center;
    color: #052C42 !important;
    font-family: Librefranklin-Regular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px;

    /* opacity: 0.9; */
}

#select-multiple-native {
    max-height: 90px;
    padding: 5px 15px;
}

.sign-up-section .body-text-fav-error {
    color: #E61F00 !important;
    font-size: 14px !important;
    padding-top: 8px;

}

.sign-up-section .fav-error-message {
    padding: 0;
    font-family: 'Sunborn-Sansone' !important;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    color: #E61F00 !important;
}

.css-6j8wv5-Input {
    margin: 0px !important;
    padding: 0;
}

#react-select-3-input {
    height: auto;
    padding: 0px 0px 0px 7px !important;
    caret-color: black !important;
    line-height: 10px;
}

.css-1jwi8f4-singleValue {
    margin-left: 8px !important;
}

.overlay {
    width: 390px;
    height: 510px;
    position: absolute;
    top: 50px;
    left: 60px;
    background-color: rgba(255, 255, 255, 0.9);
    /* padding: 20px; */
    border-radius: 16px;
    z-index: 1000;
    /* Ensure it's above the map */
    overflow: hidden;
    background: white;
}

.overlay-mobile {
    /* width: 100%; */
    right: 0;
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
    /* Ensure it's above the map */
}

.overlay .css-9fc0yf-MuiGrid-root {
    overflow: auto;
    height: 110vh;
}

[data-rsbs-scroll] {
    overflow: hidden !important;
}

/* new css for web */
.sign-up-form-fields-parent-contianer .mobile-number-verify {
    padding-right: 4px;
    color: var(--Link, #3D50DF);
    text-align: right;
    font-family: Librefranklin-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.sign-up-form-fields-parent-contianer fieldset {
    border-radius: 16px !important;
}

.sign-up-form-fields-parent-contianer .css-1je76wn-MuiFormLabel-root-MuiInputLabel-root {
    color: var(--Subtitle, rgba(5, 44, 66, 0.50)) !important;
    font-family: Librefranklin-Regular !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 150% */
    padding-left: 6px;
}

.sign-up-form-fields-parent-contianer .MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled,
.MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused,
.MuiTextField-root .MuiInputLabel-root.Mui-error {
    color: var(--Subtitle, rgba(5, 44, 66, 0.50)) !important;
    font-family: Librefranklin-Regular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    padding-left: 6px;
}

.sign-up-form-fields-parent-contianer .MuiFormControl-root .MuiInputLabel-root.Mui-focused,
.MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled,
.MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
    color: #052C42 !important;
    font-family: Librefranklin-Regular !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    padding: 14px 0px 0px 6px !important;
}

.sign-up-form-fields-parent-contianer .MuiTextField-root .MuiInputLabel-root.Mui-error.Mui-focused,
.MuiTextField-root .MuiInputLabel-root.Mui-error.MuiFormLabel-filled {
    color: #052C42 !important;
    font-family: Librefranklin-Regular !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    /* padding-left: 6px;
    padding-bottom: 5px  */
    padding: 14.5px 0px 0px 6px !important;
}


.sign-up-form-fields-parent-contianer .css-1wc848c-MuiFormHelperText-root.Mui-error {
    padding-top: 5px;
    margin-bottom: -10px;
}

.sign-up-form-fields-parent-contianer .css-1je76wn-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 1rem !important;
}

.sign-up-form-fields-parent-contianer .mobile-field-label {
    padding: 14px 0px 0px 9px !important;
}

.sign-up-form-fields-parent-contianer .sign-up-section .react-date-inputs input {
    border-radius: 16px !important;

}

.sign-up-form-fields-parent-contianer .body-text-paragraph {
    color: var(--Subtitle, rgba(5, 44, 66, 0.50));
    font-family: Librefranklin-Regular !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.5px;
    /* 145.833% */
    letter-spacing: 0.15px;
}

.sign-up-form-fields-parent-contianer .date-field .heading-variant-two {
    color: var(--Primary, #052C42) !important;
    font-family: Librefranklin-SemiBold !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 24px;
    /* 150% */
}

.sign-up-form-fields-parent-contianer .heading-variant-two {
    color: var(--Primary, #052C42) !important;
    font-family: Librefranklin-SemiBold !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 24px;
    /* 150% */
}

.sign-up-form-fields-parent-contianer .add-favourite-link-text {
    color: var(--Secondary, #006DB7);
    font-family: Librefranklin-SemiBold !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.5px;
    /* 125% */
    letter-spacing: 0.15px;
    text-decoration-line: underline;

}

.sign-up-form-fields-parent-contianer .body-text {
    color: var(--Subtitle, rgba(5, 44, 66, 0.50));
    font-family: Librefranklin-Regular !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px;
    height: 51px;
    opacity: 0.8;
}

.sign-up-form-fields-parent-contianer .invite-code-spacing-one {
    padding-top: 8px;
}

.sign-up-form-fields-parent-contianer .invite-code-spacing-two {
    margin-bottom: -8px;
}

.sign-up-form-fields-parent-contianer .body-text-one {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Librefranklin-Regular !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.15px;
}

.sign-up-form-fields-parent-contianer .body-text-one-bold {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Librefranklin-Regular !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.15px;
    text-decoration-line: underline;
}

.sign-up-form-fields-parent-contianer .submit-btn-text {
    color: #FFF;
    text-align: center;
    font-family: GritSans-Bold !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
    text-transform: uppercase;
    letter-spacing: 0.15 !important;
}

.sign-up-form-fields-parent-contianer .submit-btn button {
    letter-spacing: 0.25 !important;
}

/* overlay component css */
.overlay-parent-container .page-main-heading {
    color: var(--Primary, #052C42);
    text-align: center;
    font-family: GritSans-Bold !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 120% */
    text-transform: uppercase;
    /* padding: 21px 25px 20px 25px; */
}

.overlay-parent-container .current-location-sub-heading {
    color: var(--Primary, #052C42);
    font-family: Librefranklin-Bold !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.5px;
    /* 125% */
    letter-spacing: 0.15px;
}

.overlay-parent-container .current-location-main-heading {
    color: var(--Primary, #052C42);
    font-family: Librefranklin-Bold !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 100% */
    letter-spacing: 0.15px;
}

.overlay-parent-container .nearby-spacing {
    padding: 0px 16px;
}

.overlay-parent-container .nearby-stores-list {
    margin: 8px 16px 10px 16px !important;
    border-radius: 16px;
    box-shadow: 0px 0px 13px 0px rgba(161, 161, 161, 0.30);
    padding: 16px 20px;
    gap: 17px;
}

.overlay-parent-container .save-store-btn {
    width: 230px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 20px !important;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
}

.overlay-parent-container .location-city {
    color: var(--Primary, #052C42);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Librefranklin-SemiBold !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 114.286% */
    text-transform: initial !important;
    padding-bottom: 3px;
}

.overlay-parent-container .location-street {
    color: var(--Link, #3D50DF);
    font-family: Librefranklin-Regular !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.15px;
    text-decoration-line: underline;
}

.overlay-parent-container .location-active-hours {
    color: var(--Subtitle, rgba(5, 44, 66, 0.50));
    /* Body1 */
    font-family: Librefranklin-Regular !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.15px;
}

.overlay-parent-container .css-1s2y6gj-MuiGrid-root {
    padding-left: 16px;

}

.overlay-parent-container .MuiFormControl-root input,
.MuiFormControl-root .MuiSelect-select {
    padding: 0 !important;
    background-color: #F4F4F4;
}

.overlay-parent-container .css-ittuaa-MuiInputAdornment-root {
    margin-right: 0 !important;
    background-color: #F4F4F4;
    border-radius: 16px !important;
}

.overlay-parent-container fieldset {
    box-shadow: none !important;
    background-color: #F4F4F4;
    border-radius: 16px !important;
}

.overlay-parent-container .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    padding: 0px !important;
    padding-right: 10px !important;
}

.overlay-parent-container .css-1s2y6gj-MuiGrid-root {
    padding-left: 14px !important;
}

.overlay-parent-container .text-field-icon-parent {
    padding: 17px 8px 15px 24px;

}

.overlay-parent-container .text-field-icon-parent .text-field-icon {
    width: 19px;
    height: 19px;
    color: #052C4280;
    /* opacity: 50%; */
}

.overlay-parent-container input {
    font-size: 12px;
    background: #F4F4F4;
    border-radius: 16px !important;
    height: 40px;
}

.overlay-parent-container .MuiInputBase-root {
    gap: 26px;
}

.sign-up-form-fields-parent-contianer .location-city {
    color: var(--Primary, #052C42);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Librefranklin-Bold !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 114.286% */
    text-transform: initial !important;
    padding-bottom: 5px;
}

.sign-up-form-fields-parent-contianer .location-active-hours {
    color: var(--Subtitle, rgba(5, 44, 66, 0.50));
    /* Body1 */
    font-family: Librefranklin-Regular !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.15px;
}

input {
    -webkit-text-size-adjust: none !important;
}

/* input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
  } */

/* register form css */
@media screen and (max-width: 480px) {
    .account-creation-parent-container {}

    #main {
        display: flex;
    }

    .overlay-mobile {
        /* width:'100%'; */
        right: 0;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 0px;
        border-radius: 16px 16px 0 0;
        z-index: 1000;
        /* Ensure it's above the map */
    }

    .overlay-parent-container .page-main-heading {
        color: var(--Primary, #052C42);
        text-align: center;
        font-family: GritSans-Bold !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 120% */
        text-transform: uppercase;
        /* padding: 21px 25px 20px 25px; */
        padding: 0px;
    }

    #sign-up-register-form .footer-setting {
        display: none !important;
    }

    /* input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
      transition-delay: background-color 5000s, color 5000s;
    } */

    .sign-up-form-fields-parent-contianer .body-text-one {
        color: var(--Primary, #052C42);
    }

    .sign-up-form-fields-parent-contianer .body-text-one-bold {
        color: var(--Secondary, #006DB7);
    }

    .number-only-for-mobile {
        color: var(--Subtitle, rgba(5, 44, 66, 0.50));
        /* Body2 */
        font-family: Librefranklin-Regular !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17.5px;
        /* 125% */
        letter-spacing: 0.15px;
    }

    .recieve-sms-mobile {
        color: var(--Primary, #052C42);
        /* Body1 */
        font-family: Librefranklin-Regular !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 133.333% */
        letter-spacing: 0.15px;
    }

    .sign-up-form-fields-parent-contianer .MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled,
    .MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused,
    .MuiTextField-root .MuiInputLabel-root.Mui-error {
        font-family: Librefranklin-Regular !important;
        padding: 0px 0px 0px 0px;
        color: #052C42 !important;
        font-size: 13px !important;
        line-height: 24px !important;
        outline: none !important;
        font-weight: 400 !important;
    }

    .sign-up-form-fields-parent-contianer .MuiTextField-root .MuiInputLabel-root.Mui-focused {
        padding: 0px;
        outline: none !important;
        font-size: 13px !important;
        line-height: 24px !important;
        font-weight: 400 !important;
    }

    .sign-up-form-fields-parent-contianer .MuiTextField-root .MuiInputLabel-root.Mui-error {
        color: var(--Subtitle, rgba(5, 44, 66, 0.50)) !important;
        padding: 0px;
        font-size: 16px !important;
        outline: none !important;
    }

    .sign-up-form-fields-parent-contianer .MuiTextField-root .MuiInputLabel-root.Mui-error.Mui-focused,
    .MuiTextField-root .MuiInputLabel-root.Mui-error.MuiFormLabel-filled {
        padding: 0px 0px 0px 0px !important;
        color: #052C42 !important;
        line-height: 24px !important;
        font-size: 13px !important;
        outline: none !important;
        font-weight: 400 !important;
    }

    .sign-up-form-fields-parent-contianer .MuiFormControl-root {
        width: 100%;
        outline: none !important;
    }

    .sign-up-form-fields-parent-contianer .MuiFormControl-root .MuiInputLabel-root.Mui-focused,
    .MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled,
    .MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
        font-family: 'Librefranklin-Regular' !important;
        padding: 0px 0px 0px 0px;
        color: #052C42 !important;
        font-size: 13px !important;
        letter-spacing: 1.7px;
        line-height: 24px !important;
        outline: none !important;
        font-weight: 400 !important;
    }

    .sign-up-form-fields-parent-contianer .MuiInputBase-root {
        border-radius: 0px !important;
        padding: 0px !important;
        outline: none !important;
    }

    .sign-up-form-fields-parent-contianer .MuiTextField-root {
        width: 100%;
        outline: none !important;
    }

    .sign-up-form-fields-parent-contianer .MuiFormControl-root input,
    .MuiFormControl-root .MuiSelect-select {
        color: #000;
        border-radius: 0;
        font-family: 'Librefranklin-Regular' !important;
        caret-color: black !important;
        padding: 16px 14px !important;
        outline: none !important;
    }

    .sign-up-form-fields-parent-contianer fieldset {
        outline: none !important;
        border: 1px solid rgba(5, 44, 66, 0.23) !important;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 6%), 0px 4px 5px 0px rgb(0 0 0 / 6%), 0px 1px 10px 0px rgb(0 0 0 / 6%) !important;
    }

    .sign-up-form-fields-parent-contianer .mobile-field-label {
        padding: 0px 0px 0px 0px !important;
        background: white !important;
    }

    .mobile-field .MuiInputLabel-root.Mui-focused,
    .mobile-field .MuiInputLabel-root.Mui-focused.Mui-error {
        padding: 0 !important;
        font-size: 16px !important;
    }

    .mobile-field .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
        padding: 0px 0px 0px 0px !important;
    }

    .bg-white {
        background: white !important;
    }

    .sign-up-form-fields-parent-contianer .MuiFormControl-root .MuiInputLabel-root.Mui-focused,
    .sign-up-form-fields-parent-contianer .MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled,
    .sign-up-form-fields-parent-contianer .MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
        color: #052C42 !important;
        font-family: Librefranklin-Regular !important;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 24px !important;
        /* 150% */
        padding: 0px 0px 0px 0px !important;
    }

    .sign-up-form-fields-parent-contianer .MuiTextField-root .MuiInputLabel-root.Mui-error.Mui-focused,
    .MuiTextField-root .MuiInputLabel-root.Mui-error.MuiFormLabel-filled {
        color: #052C42 !important;
        font-family: Librefranklin-Regular !important;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 24px !important;
        /* 150% */
        /* padding-left: 6px;
    padding-bottom: 5px  */
        padding: 0px 0px 0px 0px !important;
    }

    input {
        -webkit-text-size-adjust: none !important;
    }
}

.custom-btn,
button {
    letter-spacing: 0.5px !important;
}

.overlay-parent-container .nearby-stores-list-selected {
    border: 2px solid #006DB7;
    margin: 8px 16px 10px 16px !important;
    border-radius: 16px;
    box-shadow: 0px 0px 13px 0px rgba(161, 161, 161, 0.30);
    padding: 16px 20px;
    /* height: 83px; */
    gap: 17px;
}

.location-miles-away {
    color: var(--Subtitle, rgba(5, 44, 66, 0.50)) !important;
    font-family: Librefranklin-Regular !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.15px;
}

.pick-up-unavaliable {
    color: var(--Primary, #052C42);
    text-align: center;
    font-family: GritSans-Bold !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 120% */
    text-transform: uppercase;
}

.error-dailog .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    border-radius: 16px !important;
}

.error-dailog-text {
    color: var(--label-color-light-primary, #000) !important;
    font-family: Librefranklin-Regular !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 17.5px;
    /* 125% */
    letter-spacing: 0.15px;

}