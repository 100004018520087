.list-item-styles {
    padding-top: 21px !important;
    padding-bottom: 21px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.MuiSlider-mark:nth-child(3) {
    height: 4px !important;
    width: 4px !important;
}

.MuiSlider-mark:nth-child(6) {
    height: 4px !important;
    width: 4px !important;
}