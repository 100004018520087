label.time-picker:focus-within {
  border: 1px solid #ccc;
}

.native-select .MuiNativeSelect-icon {
  fill: #fff;
}

.native-select .MuiNativeSelect-select {
  height: 44px !important;
  text-align: center !important;
  text-align-last: center !important;
  font-family: 'Librefranklin-Regular' !important;
  font-size: 15px;
  height: 50px;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 20%) !important;
  text-transform: uppercase;
  border-radius: 0 !important;
  letter-spacing: 2.25px !important;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-change {
  font-family: "Librefranklin-Regular" !important;
}

.native-select .MuiNativeSelect-select {
  color: #fff;
  padding-left: 15px !important;
}

.native-select .MuiNativeSelect-select option {
  color: #000;
  margin: 0 auto !important;
}

.native-select.MuiInputBase-root.MuiInput-root {
  margin-top: 0px !important;
}

.time-slot-selected .native-select.MuiInputBase-root.MuiInput-root::after {
  border-bottom: 3px solid #000000;
}

.native-select.MuiInputBase-root.MuiInput-root::after {
  border-bottom: 3px solid #B5B5B5;
}

.order-date .MuiInputAdornment-root {
  margin-right: 14px;
}

.order-date .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100%;
  max-width: 320px;
  margin-bottom: 20px;
}

.MuiButtonBase-root.MuiPickersDay-root:focus,
.MuiButtonBase-root.MuiPickersDay-root:hover {
  background-color: '#062C43';
  color: #fff;
}

.MuiButtonBase-root.MuiPickersDay-root:focus.Mui-selected {
  background-color: #0073BD;
}

/* new css */
.confirm-order-section {
  padding: 40px 26px;
  border-radius: 16px;
}

.confirm-order-section .confirm-order {
  padding-bottom: 16px;
}

.confirm-order-section .heading-main-confirm-order {
  color: var(--Primary, #052C42);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Libre Franklin;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: 0.15px;
}

.confirm-order-section .heading-sub-one-confirm-order {
  color: var(--Primary, #052C42);
  font-family: Librefranklin-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.333%;
  letter-spacing: 0.15px;

}

.confirm-order-section .order-gray-text {
  color: var(--Subtitle, rgba(5, 44, 66, 0.50));
  font-family: Libre Franklin;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.15px;
}

.confirm-order-section .confirm-order-card-parent-grid {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .confirm-order-section .confirm-order-text{
  padding-top: 10.5px;
} */
.confirm-order-section .amount-grid-col {
  display: flex;
  justify-content: flex-end;
}

.confirm-order-section .item-count-order-confirm {
  height: 37px;
  width: 37px;
  border-radius: 50%;
  background: #FFCE38;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: bold;
  color: var(--Primary, #052C42);
  font-family: Librefranklin-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: 0.15px;
}

.confirm-order-section .divider-hr {
  width: 94%;
  margin: auto;
  border-bottom-width: 2px;
}

.confirm-order-section .confirm-order-child {
  border-radius: 16px;
  box-shadow: 0px 0px 13px 0px rgba(161, 161, 161, 0.30);
}

.time-slot-wrapper .css-9aewle-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
  border-radius: 10px !important;

}

.time-slot-wrapper .selected-btn.MuiToggleButton-standard {
  border-radius: 10px !important;
}

.time-slot-wrapper .css-zfctgr-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  margin-top: 8px !important;
}

.time-slot-wrapper .css-1cb90fy-MuiButtonBase-root-MuiToggleButton-root {
  padding: 16px 12px !important;
}

.done-btn-time-slots {
  color: var(--Secondary, #006DB7);
  text-align: center;
  font-family: GritSans-Bold !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px !important;
  /* 112.5% */
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 42px;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 30px !important;
  border: 2px solid #006DB7 !important;
  letter-spacing: 0.25px !important;
}

.time-slot-time-list {
  color: var(--Primary, #052C42);
  font-family: Librefranklin-Regular !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 200% */
  letter-spacing: 0.15px;
}

.time-slot-wrapper .css-abdh2x-MuiButtonBase-root-MuiToggleButton-root {
  padding: 0px !important;
}

.time-slot-wrapper .css-g6t1mu-MuiButtonBase-root-MuiToggleButton-root {
  padding: 0px !important;
}

.time-slot-list::-webkit-scrollbar {
  display: none;
}

.time-slot-parent-container .css-xssij5-MuiButtonBase-root-MuiToggleButton-root {
  border: 0 !important;
}

.time-slot-parent-container .selected-btn.MuiToggleButton-standard:focus {
  outline: none !important;
}

.time-slot-parent-container .css-i8cuzu-MuiButtonBase-root-MuiToggleButton-root {
  border: 0px !important;
  align-items: start !important;
}

/* new css for mobile */
.time-slot-mobile-drawer-container .time-solt-scroll::-webkit-scrollbar {
  display: none !important;
}

.time-slot-mobile-drawer-container .schedule-delivery-text {
  color: var(--Primary, #052C42);
  text-align: center;
  font-family: GritSans-Bold !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 120% */
  text-transform: uppercase;

}

.time-slot-mobile-drawer-container .schedule-spacing {
  padding: 21px 0px;
  background: #F6F6F6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
}

.time-slot-time-list-for-mobile {
  color: var(--Primary, #052C42);
  font-family: Librefranklin-Regular !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 200% */
  letter-spacing: 0.15px;

}

.time-slot-intervals-list::-webkit-scrollbar {
  display: none !important;
}