.address-form-fields-changenumber .MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled,
.MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused,
.MuiTextField-root .MuiInputLabel-root.Mui-error {
  font-family: 'Librefranklin-Regular' !important;
  color: #052C42 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  outline: none !important;
  padding: 6px !important;
}

.address-form-fields-changenumber .MuiTextField-root .MuiInputLabel-root.Mui-focused {
  outline: none !important;
  line-height: 20px !important;
  /* padding: 24px 0 0 31px !important; */
}

.address-form-fields-changenumber .MuiTextField-root .MuiInputLabel-root.Mui-error {
  color: var(--Subtitle, rgba(5, 44, 66, 0.50)) !important;
  font-size: 16px !important;
  outline: none !important;
  /* font-size: 12px; */
  padding: 0px !important;
}

.address-form-fields-changenumber .MuiTextField-root .MuiInputLabel-root.Mui-error.Mui-focused,
.MuiTextField-root .MuiInputLabel-root.Mui-error.MuiFormLabel-filled {
  color: #052C42 !important;
  line-height: 20px !important;
  font-size: 12px !important;
  outline: none !important;
  letter-spacing: 0.15px !important;
  /* padding: 0px !important; */
  padding: 14px 0px 0px 0px !important;

}

.address-form-fields-changenumber .MuiFormControl-root {
  width: 100%;
  outline: none !important;
}

.address-form-fields-changenumber .MuiFormControl-root .MuiInputLabel-root.Mui-focused,
.MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled,
.MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
  font-family: 'Librefranklin-Regular' !important;
  color: #052C42 !important;
  font-size: 12px !important;
  letter-spacing: 0.15px;
  line-height: 20px !important;
  outline: none !important;
  padding: 14px 0px 0px 13px !important;

}

.address-form-fields-changenumber .MuiInputBase-root {
  border-radius: 0px !important;
  outline: none !important;
  height: 56px;
}

.address-form-fields-changenumber.MuiTextField-root {
  width: 100%;
  outline: none !important;
}

.address-form-fields-changenumber .MuiFormControl-root input,
.MuiFormControl-root .MuiSelect-select {
  color: #000;
  border-radius: 10px !important;
  font-family: 'Librefranklin-Regular' !important;
  caret-color: black !important;
  outline: none !important;
}

.address-form-fields-changenumber fieldset {
  border-radius: 10px;
  outline: none !important;
  border: 0 !important;
  box-shadow: 0px 0px 13px 0px rgba(161, 161, 161, 0.30) !important;
}

.address-form-fields-changenumber .MuiTextField-root .MuiInputLabel-root.Mui-error.MuiFormLabel-filled {
  color: #052C42 !important;
  padding: 24px 12px 12px 31px !important;
}

.address-form-fields-changenumber .mobile-field-label {
  background: white !important;
}

.address-form-fields-changenumber .MuiInputLabel-root.Mui-focused,
.mobile-field .MuiInputLabel-root.Mui-focused.Mui-error {
  font-size: 16px !important;
  line-height: 20px !important;
}

.address-form-fields-changenumber .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
  line-height: 20px !important;
}

.address-form-fields-changenumber .MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
  font-size: 12px !important;
  color: #052C42 !important;
  line-height: 20px !important;
  /* padding: 10px 12px 12px 17px !important; */
  padding: 24px 0px 0px 31px !important;
  letter-spacing: 0.15px !important;
}

.address-form-fields-changenumber .bg-white {
  background: white !important;
}

.address-form-fields-changenumber .save-completed-btn {
  width: 180px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 20px !important;
  margin: auto;
  margin-top: 40px;
  font-size: 16px;
  margin-bottom: 24px;
  font-family: 'GritSans-Bold' !important;
  padding-bottom: 8px;
}

/* .MuiFormControl-root .MuiInputLabel-root.Mui-focused,
.MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled,
.MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
    padding: 24px 14px 0px 31px !important;
} */

.phone-border .MuiPaper-root {
  border-radius: 16px !important;
}

@media screen and (max-width: 600px) {

  .address-form-fields-changenumber .MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled,
  .MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused,
  .MuiTextField-root .MuiInputLabel-root.Mui-error {
    font-family: Librefranklin-Regular !important;
    padding: 2px 0px !important;
    color: var(--Primary, #052C42) !important;
    font-size: 1rem !important;
  }

  .address-form-fields-changenumber .MuiTextField-root .MuiInputLabel-root.Mui-focused {
    padding: 0px !important;
    color: var(--Primary, #052C42) !important;
  }

  .address-form-fields-changenumber .MuiTextField-root .MuiInputLabel-root.Mui-error {
    /* color: var(--Primary, #052C42) !important; */
    padding: 0 0 2px 0 !important;
  }

  .address-form-fields-changenumber .MuiTextField-root .MuiInputLabel-root.Mui-error.Mui-focused,
  .MuiTextField-root .MuiInputLabel-root.Mui-error.MuiFormLabel-filled {
    padding: 2px 0 0 5px !important;
    color: var(--Primary, #052C42) !important;
  }

  .address-form-fields-changenumber .MuiFormControl-root {
    width: 100% !important;
    line-height: 1.2em !important;
  }

  .address-form-fields-changenumber .MuiFormControl-root .MuiInputLabel-root.Mui-focused,
  .address-form-fields-changenumber .MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled,
  .address-form-fields-changenumber .MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
    font-family: Librefranklin-Regular !important;
    padding: 0px 0px !important;
    color: var(--Primary, #052C42) !important;
    font-size: 12px !important;
    letter-spacing: 2px !important;
  }

  .address-form-fields-changenumber .MuiInputBase-root {
    border-radius: 4px !important;
    padding: 8px 12px !important;
    color: var(--Primary, #052C42) !important;
  }

  .address-form-fields-changenumber .MuiTextField-root {
    width: 100% !important;
  }

  .address-form-fields-changenumber .MuiFormControl-root input,
  .address-form-fields-changenumber .MuiFormControl-root .MuiSelect-select {
    color: var(--Primary, #052C42) !important;
    border-radius: 4px !important;
    font-family: Librefranklin-Regular !important;
    color: var(--Primary, #052C42) !important;
    padding: 8px 2.5px !important;
  }

  .address-form-fields-changenumber fieldset {
    color: var(--Primary, #052C42) !important;
    border-radius: 8px !important;
    padding: 0 8px !important;
    box-shadow: none !important;
    border: 1px solid #006DB7 !important;
    /* line-height: 1.5em !important; */
  }

  .phone-border .MuiPaper-root {
    border-radius: 0px !important;
  }

  .address-form-fields-changenumber .save-completed-btn {
    width: 230px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 20px !important;
    margin: auto;
    margin-top: 40px;
    font-size: 18px;
    margin-bottom: 24px;
    font-family: 'GritSans-Bold' !important;
    padding-bottom: 8px;
  }
}