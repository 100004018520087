.list-sec li {
  padding-left: 38px;
}

@media screen and (max-width: 468px) {
  .list-sec li {
    padding-left: 20px;
  }
}


.list-sec::-webkit-scrollbar {
  display: none;
}