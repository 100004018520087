.pick-up-unavaliable-store {
  color: var(--Primary, #052c42);
  text-align: center;
  font-family: 'GritSans-Bold' !important;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 120% */
  text-transform: uppercase;
}

.error-dailog-store .MuiPaper-root {
  border-radius: 16px !important;
}

@media only screen and (max-width: 480px) {
  .error-dailog-store .MuiPaper-root {
    border-radius: 14px !important;
    background: rgba(242, 242, 242, 0.8);
    backdrop-filter: blur(11px);
    width: 300px;
    max-width: 300px;
  }

  .error-dailog-text-store {
    width: 253px;
  }
}

.error-dailog-text-store {
  color: var(--label-color-light-primary, #000) !important;
  font-family: 'Librefranklin-Regular' !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px;
  /* 125% */
  letter-spacing: 0.15px;
}
