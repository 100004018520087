.scan-point-qr {
    border-radius: 0px !important;
    box-shadow: none !important;
    height: 153px;
    width: 153px;
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 22px;
}

.forget-check-in-parent fieldset {
    border-radius: 16px !important;
}

@media (min-width: 599px) and (max-width: 701px) {
    .my-reward-challenges {
        padding-left: 4rem !important;
    }
}

@media only screen and (max-width: 480px) {
    .scan-point-qr {
        border-radius: 0px !important;
        box-shadow: none !important;
        height: 150px;
        width: 150px;
        padding: 12px 0px;
        background-color: transparent !important;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

#onetrust-banner-sdk {
    display: none;
}